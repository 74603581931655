import React, { useState } from "react";
import { savePDF } from "@progress/kendo-react-pdf";

import Styles from "./Style.module.css";
import Add from "./assests/add.png";
import DownLoad from "./assests/download-free-icon-font.png";
import Refresh from "./assests/refresh.png";
import Lock from "./assests/lock.png";
import Unlock from "./assests/unlock.png";
import Favicon from "./assests/favicon.png";
import Eye from "./assests/eye.png";
import EyeCrossed from "./assests/eye-crossed.png";

function App() {
  const [passwords, setPasswords] = useState([
    { id: 1, password: "", ph: "", compte: "", hidden: false },
    { id: 2, password: "", ph: "", compte: "", hidden: false },
  ]);
  const [secret, setSecret] = useState("secret");
  const [decryptPassword, setDecryptPassword] = useState("");
  const [desireDecrypt, setDesireDecrypt] = useState("");
  const [mode, setMode] = useState(true);
  const [hiddePassword, setHiddePassword] = useState(false);

  const contentArea = React.useRef(null);

  const exportPDFWithMethod = () => {
    savePDF(contentArea.current, {
      paperSize: "A4",
      scale: 0.8,
      margin: 10,
      fileName: "Encrypted passwords list",
    });
  };

  const handleCrypt = async (e) => {
    if (secret === "") {
      alert("Vous devez d'abord choisir un mot secret");
    } else {
      let subs = [];
      let hashed = [];
      const splitPassword = e.target.value.split("");
      for (let i = 0; i < splitPassword.length; i += secret.length) {
        subs.push(splitPassword.slice(i, i + secret.length));
      }

      for (let i = 0; i < subs.length; i++) {
        for (let y = 0; y < subs[i].length; y++) {
          hashed.push(subs[i][y].charCodeAt() + y + secret[y].charCodeAt());
        }
      }

      setPasswords(
        [
          ...passwords.filter((ele) => ele.id !== parseInt(e.target.id)),
          {
            id: parseInt(e.target.id),
            password: hashed.join(" "),
            ph: e.target.value,
            compte: passwords.filter((ele) => ele.id === parseInt(e.target.id))[0].compte,
            hidden: passwords.filter((ele) => ele.id === parseInt(e.target.id))[0].hidden
          },
        ].sort((a, b) => a.id - b.id)
      );
      subs = [];
    }
  };

  const handleDeCrypt = (e) => {
    if (secret === "") {
      alert("Vous devez d'abord choisir un mot secret");
    } else{
      setDesireDecrypt(e.target.value);
      setDecryptPassword("");
      const hashedPassword = e.target.value.split(" ");
      const newArray = [];
      const decrypt = [];
      for (let i = 0; i < hashedPassword.length; i += secret.length) {
        newArray.push(hashedPassword.slice(i, i + secret.length));
      }
  
      for (let i = 0; i < newArray.length; i++) {
        for (let y = 0; y < newArray[i].length; y++) {
          decrypt.push(
            String.fromCharCode(
              parseInt(newArray[i][y]) - y - secret[y].charCodeAt()
            )
          );
        }
      }
      setDecryptPassword(decrypt.join(""));
    }
  };

  const handleSetSecret = (e) => {
    setSecret(e.target.value);
  };

  const handleAddOne = () => {
    setPasswords([...passwords, { id: passwords.length + 1, password: "", compte: "", hidden: false }]);
  };

  const handleDecryptPassword = () => {
    setMode(!mode);
  };

  const handleRefresh = () => {
    setPasswords([
      { id: 1, password: "", ph: "", compte: "", hidden: false },
      { id: 2, password: "", ph: "", compte: "", hidden: false },
    ]);
    setSecret("secret");
    setDesireDecrypt("");
    setDecryptPassword("");
  };

  const handleSelectCompteName = (e) =>{
    const id = parseInt(e.target.id)/100
    setPasswords(
      [
        ...passwords.filter((ele) => ele.id !== id),
        {
          id: id,
          password: passwords.filter(ele=>ele.id === id)[0].password,
          ph: passwords.filter(ele=>ele.id === id)[0].ph,
          compte: e.target.value,
          hidden: passwords.filter((ele) => ele.id === id)[0].hidden
        },
      ].sort((a, b) => a.id - b.id)
    );
  };

  const handleHiddePassword = (e) =>{
    setPasswords(
      [
        ...passwords.filter((ele) => ele.id !== parseInt(e.target.id)),
        {
          id: parseInt(e.target.id),
          password: passwords.filter(ele=>ele.id === parseInt(e.target.id))[0].password,
          ph: passwords.filter(ele=>ele.id === parseInt(e.target.id))[0].ph,
          compte: passwords.filter(ele=>ele.id === parseInt(e.target.id))[0].compte,
          hidden: !passwords.filter((ele) => ele.id === parseInt(e.target.id))[0].hidden
        },
      ].sort((a, b) => a.id - b.id)
    );
  };

  return (
    <div className={Styles.App}>
      <div className={Styles.AppTitleContainer}>
        <div className={Styles.AppHeaderC}>
          <a href="https://encrypt.pelegrand.tech" className={Styles.Link}>
            <img src={Favicon} alt="logo" className={Styles.Favicon} />
          </a>
          <a href="https://encrypt.pelegrand.tech" className={Styles.Link}>
            <span className={Styles.FaviconSpan}>ENCRYPT</span>
          </a>
        </div>
        <div className={Styles.AppHeaderC2}>
          <div className={Styles.AppLogoContainer}>
            <img src={Lock} alt="logo" className={Styles.AppLogo} />
          </div>
          <h1 className={Styles.AppTitle}>Chiffrez vos mots de passe</h1>
        </div>
        <div className={Styles.AppHeaderC}></div>
      </div>
      <div className={Styles.AppSecretContainer}>
        {mode ? (
          <p className={Styles.AppExplTitle}>
            Chiffrez vos mots de passe en quelques secondes. Vous n'aurez plus
            qu'un mot secret à retenir.
          </p>
        ) : (
          <p className={Styles.AppExplTitle}>
            Déchiffrez vos mots de passe en quelques secondes grâce à votre mot
            secret.
          </p>
        )}
        <div className={Styles.Expl}>
          {mode ? (
            <>
              <div className={Styles.ExplSmall}>
                <div className={Styles.ExplSmallNum}>1</div>
                <div className={Styles.ExplSmallTitle}>
                  Choisissez un mot ou une phrase secrète. C'est l'unique mot
                  que vous devrez retenir afin de déchiffrer vos mots de passe.
                </div>
              </div>
              <div className={Styles.ExplSmall}>
                <div className={Styles.ExplSmallNum}>2</div>
                <div className={Styles.ExplSmallTitle}>
                  Entrez tous les mots de passe que vous souhaitez chiffrer.
                </div>
              </div>
              <div className={Styles.ExplSmall}>
                <div className={Styles.ExplSmallNum}>3</div>
                <div className={Styles.ExplSmallTitle}>
                  Exportez vos mots de passe chiffrés au format pdf et revenez
                  quand vous aurez besoin d'en déchiffrer un.
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={Styles.ExplSmall}>
                <div className={Styles.ExplSmallNum}>1</div>
                <div className={Styles.ExplSmallTitle}>
                  Entre le mot ou la phrase secrète que vous aviez choisi lors
                  du chiffrement.
                </div>
              </div>
              <div className={Styles.ExplSmall}>
                <div className={Styles.ExplSmallNum}>2</div>
                <div className={Styles.ExplSmallTitle}>
                  Entrez le mot de passe que vous souhaitez déchiffrer.
                  Attention les espaces entre les blocs de chiffres sont
                  importants.
                </div>
              </div>
              <div className={Styles.ExplSmall}>
                <div className={Styles.ExplSmallNum}>3</div>
                <div className={Styles.ExplSmallTitle}>
                  Votre mot de passe est déchiffré.
                </div>
              </div>
            </>
          )}
        </div>
        <input
          className={Styles.InputSecret}
          onChange={handleSetSecret}
          value={secret}
          autoComplete="off"
        ></input>
      </div>
      {mode ? (
        <div className={Styles.BodyContainer}>
          <div className={Styles.BodySmallContainer}>
            {passwords.map((element, index) => {
              return (
                <div className={Styles.BodySmallContainerElementContainer} key={index}>
                  <div className={Styles.BodySmallContainerOrganisator}>
                    <div className={Styles.ExplSmallNum}>{element.id}</div>
                  </div>
                  <input
                    className={Styles.CompteInput}
                    value={element.compte}
                    onChange={handleSelectCompteName}
                    id={element.id*100}
                    autoComplete="off"
                  ></input>
                  <input
                    className={Styles.Input}
                    key={element.id}
                    id={element.id}
                    value={element.ph}
                    onChange={handleCrypt}
                    autoComplete="off"
                    maxLength="23"
                    type={element.hidden? 'password':'text'}
                  ></input>
                  {element.hidden?
                    <div className={Styles.EyeLogoContainer} onClick={handleHiddePassword}>
                      <img src={Eye} alt='EyeCrossed'  className={Styles.EyeLogo} id={element.id}/>
                    </div>
                  :
                    <div className={Styles.EyeLogoContainer} onClick={handleHiddePassword}>
                      <img src={EyeCrossed} alt='EyeCrossed' className={Styles.EyeLogo} id={element.id}/>
                    </div>
                  }
                  <div className={Styles.CryptedPasswordsContainerSmallScreen}>
                    <p className={Styles.ResultCryptedVisible}>
                      <span className={Styles.SmallScreenResultSpan}>
                        Mot de passe chiffré :
                      </span>{" "}
                      {element.password}
                    </p>
                  </div>
                </div>
              );
            })}
            <div className={Styles.FunctionContainer}>
              <div className={Styles.FunctionSmallContainer}>
                <img
                  src={Add}
                  alt="AddIcon"
                  className={Styles.AddIcon}
                  onClick={handleAddOne}
                />
                <div className={Styles.FunctionSmallExpl}>
                  <span>Ajouter un mot de passe</span>
                </div>
              </div>
              <div className={Styles.FunctionSmallContainerDownLoad}>
                <img
                  src={DownLoad}
                  alt="DownLoad"
                  className={Styles.DownIcon}
                  onClick={exportPDFWithMethod}
                />
                <div className={Styles.FunctionSmallExpl}>
                  <span>Exporter au format PDF</span>
                </div>
              </div>
              <div className={Styles.FunctionSmallContainer}>
                <img
                  src={Unlock}
                  alt="Unlock"
                  className={Styles.DownIcon}
                  onClick={handleDecryptPassword}
                />
                <div className={Styles.FunctionSmallExpl}>
                  <span>Déchiffrer un mot de passe</span>
                </div>
              </div>
              <div className={Styles.FunctionSmallContainer}>
                <img
                  src={Refresh}
                  alt="DLIcon"
                  className={Styles.DownIcon}
                  onClick={handleRefresh}
                />
                <div className={Styles.FunctionSmallExpl}>
                  <span>Réinitialiser les champs</span>
                </div>
              </div>
            </div>
          </div>
          <div ref={contentArea} className={Styles.CryptedPasswordsContainer}>
            {passwords.map((element, index) => {
              return (
                <div className={Styles.ResultCrypted} key={index}>
                  <p className={Styles.ResultCryptedVisible}>
                    {element.compte.normalize("NFD").replace(/[\u0300-\u036f]/g, "")} {element.password}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={Styles.BodyContainer}>
          <div className={Styles.BodySmallContainer}>
            <div className={Styles.BodySmallContainerElementContainer}>
              <div className={Styles.DecryptOrder}></div>
              <input
                className={Styles.Input}
                value={desireDecrypt}
                onChange={handleDeCrypt}
                autoComplete="off"
              ></input>
              <div className={Styles.CryptedPasswordsContainerSmallScreen}>
                <p className={Styles.ResultCryptedVisible}>
                  <span className={Styles.SmallScreenResultSpan}>
                    Mot de passe déchiffré :
                  </span>{" "}
                  {decryptPassword}
                </p>
              </div>
            </div>
            <div className={Styles.FunctionContainer}>
              <div className={Styles.FunctionSmallContainer}>
                <img
                  src={Lock}
                  alt="Unlock"
                  className={Styles.DownIcon}
                  onClick={handleDecryptPassword}
                />
                <div className={Styles.FunctionSmallExpl}>
                  <span>Chiffrer des mots de passe</span>
                </div>
              </div>
              <div className={Styles.FunctionSmallContainer}>
                <img
                  src={Refresh}
                  alt="DLIcon"
                  className={Styles.DownIcon}
                  onClick={handleRefresh}
                />
                <div className={Styles.FunctionSmallExpl}>
                  <span>Réinitialiser les champs</span>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.BodySmallContainerDecrypt}>
            <div className={Styles.BodySmallContainerElementContainer}>
              <p className={Styles.ResultCryptedVisible}>{decryptPassword}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
